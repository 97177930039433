import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps, Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import {
    CreateBase,
    SimpleForm,
    useNotify,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import DialogTitle from './DialogTitle';

interface ICreateDialogButtonProps extends ButtonProps {
    resource: string;
    formFileds: React.ReactNode;
    buttonText?: string;
    defaultValues?: object;
}

const CreateDialogButton: React.FunctionComponent<ICreateDialogButtonProps> = ({
    resource,
    formFileds,
    buttonText,
    defaultValues,
    ...rest
}) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const title = resource.charAt(0).toUpperCase() + resource.slice(1);
    const record = useRecordContext();
    const refresh = useRefresh();
    const relatedTo = record?.company || record?.email;

    const onSuccess = () => {
        setOpen(false);
        notify(`${title} created`, { type: 'success' });
        refresh();
    };

    const onError = () => {
        notify(`${title} creation failed. Please try again.`, {
            type: 'error',
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                aria-label="create"
                onClick={handleClickOpen}
                {...rest}
            >
                <AddIcon style={{ marginRight: '4px' }} />
                {buttonText || `Add ${title}`}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle id="form-dialog-title" onClose={handleClose}>
                    Create {title} {relatedTo && `for ${relatedTo}`}
                </DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <CreateBase
                        resource={resource}
                        mutationOptions={{ onSuccess, onError }}
                    >
                        <SimpleForm
                            sx={{ padding: 4 }}
                            defaultValues={{
                                ...defaultValues,
                                customer_id: record?.id,
                            }}
                        >
                            {formFileds}
                        </SimpleForm>
                    </CreateBase>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreateDialogButton;
