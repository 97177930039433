import * as React from 'react';
import {
    List,
    NullableBooleanInput,
    ResourceComponentInjectedProps,
    SelectInput,
    TextInput,
} from 'react-admin';
import {
    licenseTypes,
    paymentIntervals,
    subscriptionTypes,
} from '../shared/SelectInput';
import SubscriptionDatagrid from './SubscriptionDatagrid';

const filters = [
    <TextInput
        key="search"
        source="alt_id@_like,customer#email@_ilike,customer#company@_ilike,customer#first_name@_ilike,customer#last_name@_ilike"
        label="Search"
        resettable
        alwaysOn
    />,
    <NullableBooleanInput
        key="valid-subscription-filter"
        label="Valid only"
        source="is_valid"
        alwaysOn
    />,
    <SelectInput
        key="type"
        label="Subscription"
        source="type"
        choices={subscriptionTypes}
    />,
    <SelectInput
        key="license"
        label="License"
        source="license#type@_eq"
        choices={licenseTypes}
    />,
    <SelectInput
        key="licenseNot"
        label="License not"
        source="license#type@_neq"
        choices={licenseTypes}
    />,
    <SelectInput
        key="payment_interval"
        label="Payment Interval"
        source="license#payment_interval@_eq"
        choices={paymentIntervals}
    />,
];
const SubscriptionList = (
    props: ResourceComponentInjectedProps
): React.ReactElement => (
    <List
        sort={{ field: 'remaining_days', order: 'ASC' }}
        filters={filters}
        hasCreate={false}
        {...props}
    >
        <SubscriptionDatagrid />
    </List>
);

export default SubscriptionList;
