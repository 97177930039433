import { buildFields, FetchType, ResourceType } from 'ra-data-hasura';
import { GET_LIST, GET_MANY_REFERENCE, GET_ONE } from 'react-admin';
import { GET_LIST_ADDRESS, GET_ONE_ADDRESS } from '../resources/address';
import { GET_LIST_CUSTOMER, GET_ONE_CUSTOMER } from '../resources/customer';
import { GET_LIST_PREDICTION } from '../resources/feedback';
import {
    GET_LIST_SUBSCRIPTION,
    GET_ONE_SUBSCRIPTION,
} from '../resources/subscription';
import { GET_LIST_USER, GET_ONE_USER } from '../resources/user';

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
// eslint-disable-next-line
const extractFieldsFromQuery = (queryAst: any) =>
    queryAst.definitions[0].selectionSet.selections;

// An object of all the custom queries we have defined.
// eslint-disable-next-line
const CUSTOM_QUERIES: any = {
    customer: {
        [GET_LIST]: GET_LIST_CUSTOMER,
        [GET_ONE]: GET_ONE_CUSTOMER,
    },
    subscription: {
        [GET_LIST]: GET_LIST_SUBSCRIPTION,
        [GET_ONE]: GET_ONE_SUBSCRIPTION,
        [GET_MANY_REFERENCE]: GET_LIST_SUBSCRIPTION,
    },
    user: {
        [GET_LIST]: GET_LIST_USER,
        [GET_ONE]: GET_ONE_USER,
        [GET_MANY_REFERENCE]: GET_LIST_USER,
    },
    address: {
        [GET_LIST]: GET_LIST_ADDRESS,
        [GET_ONE]: GET_ONE_ADDRESS,
        [GET_MANY_REFERENCE]: GET_LIST_ADDRESS,
    },
    prediction: {
        [GET_LIST]: GET_LIST_PREDICTION,
    },
};

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields = (type: ResourceType, fetchType: FetchType) => {
    const resourceName: string = type.name;

    const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

    if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
        return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
    }

    return buildFields(type, fetchType);
};

export default customBuildFields;
