import gql from 'graphql-tag';

export const GET_LIST_ADDRESS = gql`
    {
        id
        alt_id
        created_at
        type
        line1
        line2
        city
        postal_code
        country
        customer {
            id
            email
        }
    }
`;

export const GET_ONE_ADDRESS = gql`
    {
        id
        alt_id
        created_at
        type
        line1
        line2
        city
        postal_code
        country
        customer_id
        customer {
            id
            email
            company
        }
    }
`;
