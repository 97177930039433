import * as React from 'react';
import { List, ResourceComponentInjectedProps, TextInput } from 'react-admin';
import UserDatagrid from './UserDatagrid';

const filters = [
    <TextInput
        key="search"
        source="alt_id@_ilike,login@_ilike,customer#email@_ilike"
        label="Search"
        alwaysOn
        resettable
    />,
];

const UserList = (
    props: ResourceComponentInjectedProps
): React.ReactElement => (
    <List
        {...props}
        sort={{ field: 'created_at', order: 'DESC' }}
        bulkActionButtons={false}
        filters={filters}
    >
        <UserDatagrid />
    </List>
);

export default UserList;
