import * as React from 'react';
import {
    Datagrid,
    DateField,
    EmailField,
    List,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import {
    customerSizeChoices,
    customerTypeChoices,
} from '../shared/SelectInput';

const filters = [
    <TextInput
        key="search"
        source="first_name@_ilike,last_name@_ilike,company@_ilike,email@_ilike"
        label="Search"
        alwaysOn
        resettable
        fullWidth
    />,
    <SelectInput key="type" source="type" choices={customerTypeChoices} />,
    <SelectInput key="size" source="size" choices={customerSizeChoices} />,
];

const CustomerList = (): React.ReactElement => (
    <List sort={{ field: 'created_at', order: 'DESC' }} filters={filters}>
        <Datagrid bulkActionButtons={false} rowClick="edit" size="medium">
            <DateField source="created_at" locales="de-DE" showTime />
            <TextField source="company" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <EmailField source="email" />
            <TextField source="size" />
        </Datagrid>
    </List>
);

export default React.memo(CustomerList);
