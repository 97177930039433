import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { ReferenceManyField, useRecordContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import UserDatagrid from '../user/UserDatagrid';

interface IUsersProps {}

const Users: React.FunctionComponent<IUsersProps> = () => {
    const record = useRecordContext();
    const location = useLocation();

    const shouldRender = location.pathname.endsWith('/4');
    if (!shouldRender || !record) return null;

    const relatedTo = record?.company || record?.email;

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Users
                </Typography>
            </Box>
            <ReferenceManyField
                reference="user"
                target="customer_id"
                label="Users"
            >
                <UserDatagrid relatedTo={relatedTo} />
            </ReferenceManyField>
        </>
    );
};

export default React.memo(Users);
