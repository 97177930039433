export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};

// Custom API scopes
export const scopeRequest = {
    scopes: [
        `api://${process.env.REACT_APP_AUTH_CLIENT_ID}/nostic_data_engine_access`,
    ],
};
