import { Box } from '@mui/material';
import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    ReferenceInput,
    required,
    SimpleFormProps,
    TextInput,
} from 'react-admin';

interface IChannelFormFieldsProps extends Omit<SimpleFormProps, 'children'> {
    disableTextFields?: boolean;
}

const ChannelFormFields: React.FunctionComponent<IChannelFormFieldsProps> = ({
    disableTextFields = false,
}) => (
    <>
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
                gap: '8px',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                    source="distributor_id"
                    label="Distributor ID"
                    validate={required()}
                    fullWidth
                    disabled={disableTextFields}
                />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                    source="id"
                    label="App Client ID"
                    validate={required()}
                    fullWidth
                    disabled={disableTextFields}
                />
            </Box>
        </Box>
        <ReferenceInput
            source="license_id"
            reference="license"
            filterToQuery={(s: string) => ({
                'type@_ilike': s,
            })}
            validate={required()}
        >
            <AutocompleteInput
                optionText={(record) =>
                    `${record?.country ? record?.country + ' - ' : ''} ${
                        record?.type
                    } - ${record?.product}`
                }
                debounce={1000}
                fullWidth
            />
        </ReferenceInput>
        <BooleanInput source="enabled" validate={required()} fullWidth />
    </>
);

export default React.memo(ChannelFormFields);
