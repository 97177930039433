import { Link } from '@mui/material';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { singular } from 'pluralize';
import * as React from 'react';
import { Labeled, RaRecord, useRecordContext, useRedirect } from 'react-admin';

interface ILinkFieldProps {
    source: string;
    value: string[];
    label?: string;
}

const LinkField: React.FC<ILinkFieldProps> = (props) => {
    const { source, label, value } = props;
    const record = useRecordContext(props);
    const sourceArray = source.split('.');
    const arrayFieldName = singular(sourceArray[sourceArray.length - 1]);
    const records: RaRecord[] | RaRecord = get(record, source);
    const redirect = useRedirect();

    const createLink = (r: RaRecord) => (
        <Link
            key={r.id}
            underline="none"
            component="button"
            variant="body2"
            onClick={(e) => {
                e.stopPropagation();
                redirect(`/${arrayFieldName}/${r.id}`);
            }}
        >
            {value.reduce((prev: string, curr: string, i: number) => {
                const prefix = i > 0 ? ' - ' : '';
                return prev + prefix + r[curr];
            }, '')}
        </Link>
    );

    if (!records) return null;

    let links;

    if (isArray(records)) {
        links = records.map((r: RaRecord) => createLink(r));
    } else {
        links = createLink(records as RaRecord);
    }

    return label ? (
        <Labeled label={label}>
            <span>{links}</span>
        </Labeled>
    ) : (
        <span>{links}</span>
    );
};

export default LinkField;
