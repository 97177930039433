import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import {
    RaRecord,
    useNotify,
    useRecordContext,
    useRefresh,
    useUpdate,
} from 'react-admin';
import EditDialog from './EditDialog';

interface IEditDialogButtonProps {
    resource: string;
    formFileds: React.ReactNode;
    relatedTo?: string;
    show?: (record: RaRecord) => boolean;
}

const EditDialogButton: React.FunctionComponent<IEditDialogButtonProps> = ({
    resource,
    formFileds,
    relatedTo,
    show,
}) => {
    const [open, setOpen] = useState(false);
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    if (show && !show(record)) return null;

    const title = resource.charAt(0).toUpperCase() + resource.slice(1);
    const handleSubmit = (data: any) => {
        update(
            resource,
            { id: data.id, data },
            {
                onSuccess: () => {
                    setOpen(false);
                    refresh();
                    notify(`${title} changed`, {
                        type: 'success',
                    });
                },
                onError: () => {
                    notify('An error occured. Please try again.', {
                        type: 'error',
                    });
                },
            }
        );
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button color="primary" aria-label="Edit" onClick={handleClickOpen}>
                <EditIcon style={{ marginRight: '4px' }} /> Edit
            </Button>
            {open && (
                <EditDialog
                    open={open}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                    formFileds={formFileds}
                    relatedTo={relatedTo}
                    resource={resource}
                />
            )}
        </>
    );
};

export default EditDialogButton;
