import { Dialog, DialogContent } from '@mui/material';
import * as React from 'react';
import {
    DeleteWithConfirmButton,
    Loading,
    SaveButton,
    SimpleForm,
    Toolbar,
    useGetOne,
    useRecordContext,
} from 'react-admin';
import DialogTitle from './DialogTitle';

interface IEditDialogProps {
    resource: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
    relatedTo?: string;
    formFileds: React.ReactNode;
}

const CustomToolbar = (props: any) => (
    <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
        <SaveButton />
        <DeleteWithConfirmButton />
    </Toolbar>
);

const EditDialog: React.FunctionComponent<IEditDialogProps> = ({
    resource,
    open,
    onClose,
    onSubmit,
    relatedTo,
    formFileds,
}) => {
    const title = resource.charAt(0).toUpperCase() + resource.slice(1);
    const record = useRecordContext();
    const {
        data: subscriptionData,
        isLoading,
        error,
    } = useGetOne(resource, { id: record.id });

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle id="form-dialog-title" onClose={onClose}>
                Edit {title} {relatedTo && `for ${relatedTo}`}
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <SimpleForm
                    record={subscriptionData}
                    onSubmit={onSubmit}
                    sx={{ padding: 4 }}
                    toolbar={<CustomToolbar />}
                >
                    {formFileds}
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

export default EditDialog;
