/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    RaRecord,
    ReferenceField,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import CreateDialogButton from '../../components/dialog-button/CreateDialogButton';
import EditDialogButton from '../../components/dialog-button/EditDialogButton';
import ChannelFormFields from './ChannelFormFields';

const filters = [
    <TextInput
        key="search"
        source="id@_ilike,distributor_id@_ilike"
        label="Search"
        alwaysOn
        resettable
    />,
];

const ChannelListActions = () => (
    <TopToolbar>
        <CreateDialogButton
            resource="channel"
            formFileds={<ChannelFormFields />}
            variant="text"
            buttonText="Create"
            size="small"
        />
        <ExportButton />
        <FilterButton />
    </TopToolbar>
);

const ChannelList = (props: any): React.ReactElement => (
    <List
        {...props}
        actions={<ChannelListActions />}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={filters}
        empty={false}
    >
        <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
            <TextField source="distributor_id" label="Distributor ID" />
            <TextField source="id" label="App Client ID" />
            <BooleanField source="enabled" />
            <ReferenceField
                source="license_id"
                reference="license"
            ></ReferenceField>
            <DateField source="created_at" locales="de-DE" showTime />
            <DateField source="updated_at" locales="de-DE" showTime />
            <EditDialogButton
                resource="channel"
                formFileds={<ChannelFormFields disableTextFields />}
            />
        </Datagrid>
    </List>
);

export default ChannelList;
