import * as React from 'react';
import {
    AutocompleteInput,
    Labeled,
    ReferenceInput,
    required,
    TextField,
} from 'react-admin';

interface IUserFormFieldsProps {}

const UserFormFields: React.FunctionComponent<IUserFormFieldsProps> = () => (
    <>
        <Labeled label="Username">
            <TextField source="login" />
        </Labeled>
        <ReferenceInput
            label="Customer"
            source="customer_id"
            reference="customer"
            resettable
        >
            <AutocompleteInput
                filterToQuery={(s: string) => ({
                    'email@_ilike,company@_ilike': s,
                })}
                validate={required()}
                optionText={(record) =>
                    `${record?.company || 'No company'} - ${record?.email}`
                }
                suggestionLimit={5}
                debounce={1000}
                fullWidth
            />
        </ReferenceInput>
    </>
);

export default UserFormFields;
