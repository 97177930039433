import {
    addDays,
    differenceInCalendarDays,
    format,
    formatISO,
    parseISO,
    subDays,
} from 'date-fns';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_ISO_FORMAT = 'yyyy-MM-dd';

export type DaterangePart = 'from' | 'to';

export const toDbDaterange = (from: string, to: string) => {
    let toDateDbStr = to;
    if (toDateDbStr) {
        const toDateDb = addDays(parseISO(to), 1);
        toDateDbStr = formatISO(toDateDb, {
            representation: 'date',
        });
    }
    return `[${from},${toDateDbStr})`;
};
export const splitDaterange = (daterange: string): string[] => {
    const subStr = daterange.slice(1, daterange.length - 1);
    return subStr.split(',');
};

export const parseIsoDateStr = (isoDateStr: string): string => {
    const date = parseISO(isoDateStr);
    return format(date, DATE_FORMAT);
};

export const doesStartInFuture = (daterange: string): boolean => {
    const dates = splitDaterange(daterange);
    const from = parseISO(dates[0]);
    const difference = differenceInCalendarDays(from, new Date());
    return difference > 0;
};

export const formatDaterange = (daterange: string): string => {
    if (!daterange) return 'no validity found';
    const range = splitDaterange(daterange);

    const from = range[0] ? format(parseISO(range[0]), DATE_FORMAT) : 'open';
    let to = 'open';
    if (range[1]) {
        const toDate = subDays(parseISO(range[1]), 1);
        to = format(toDate, DATE_FORMAT);
    }
    return `${from} - ${to}`;
};

export const addDayToIsoDate = (isoDate: string): string => {
    const finalDate = addDays(parseISO(isoDate), 1);
    return formatISO(finalDate, {
        representation: 'date',
    });
};

export const substractDayFromIsoDate = (isoDate: string): string => {
    const finalDate = subDays(parseISO(isoDate), 1);
    return formatISO(finalDate, {
        representation: 'date',
    });
};

export const parseValidityPart = (part: DaterangePart, daterange: string) => {
    if (!daterange) return '';
    const range = splitDaterange(daterange);
    let result;
    if (part === 'from') {
        result = range[0] || '';
    } else {
        result = range[1] ? substractDayFromIsoDate(range[1]) : '';
    }
    return result;
};

export const parseDaterangePart = (
    part: DaterangePart,
    daterange: string
): string => parseIsoDateStr(parseValidityPart(part, daterange));
