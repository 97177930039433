/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, CardContent, CardHeader } from '@mui/material';
import * as React from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { TotalsPerDay } from './predictionsHelper';

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

const dayFormatter = (day: number): string => {
    // eslint-disable-next-line default-case
    switch (day) {
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        case 7:
            return 'Sunday';
    }
    return 'unknown';
};

export interface Period {
    label: string;
    color: string;
    totalsPerDay: TotalsPerDay[];
}
export interface ILineUsageChartProps {
    title: string;
    data?: TotalsPerDay[];
    dataKeys?: DataKey[];
    periods?: Period[];
    yScale: string;
}

export interface DataKey {
    name: string;
    label: string;
    color: string;
}

const LineUsageChart: React.FunctionComponent<ILineUsageChartProps> = (
    props
) => {
    const { title, data, dataKeys, periods, yScale } = props;

    return (
        <Card>
            <CardHeader title={title} />
            <CardContent>
                <ResponsiveContainer width="100%" minHeight={350}>
                    <LineChart data={data} style={{ position: 'absolute' }}>
                        <defs>
                            <linearGradient
                                id="colorUv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="5%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.3}
                                />
                            </linearGradient>
                        </defs>
                        <Legend
                            layout="horizontal"
                            verticalAlign="top"
                            align="center"
                        />
                        {periods ? (
                            <XAxis
                                dataKey="weekday"
                                tickFormatter={dayFormatter}
                                allowDuplicatedCategory={false}
                                reversed
                            />
                        ) : (
                            <XAxis
                                dataKey="date"
                                tickFormatter={dateFormatter}
                                reversed
                            />
                        )}

                        <YAxis dataKey={yScale} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            formatter={(value: any) =>
                                new Intl.NumberFormat(undefined, {
                                    style: 'decimal',
                                }).format(value as any)
                            }
                            labelFormatter={(label: any) =>
                                periods
                                    ? dayFormatter(label)
                                    : dateFormatter(label)
                            }
                        />
                        {dataKeys &&
                            dataKeys.map((key) => (
                                <Line
                                    key={key.name}
                                    type="monotone"
                                    dataKey={key.name}
                                    name={key.label}
                                    stroke={key.color}
                                    strokeWidth={2}
                                    fill="url(#colorUv)"
                                />
                            ))}
                        {periods &&
                            periods.length > 1 &&
                            periods.map((period) => (
                                <Line
                                    key={period.label}
                                    type="monotone"
                                    data={period.totalsPerDay}
                                    name={period.label}
                                    stroke={period.color}
                                    dataKey="totalPredictions"
                                    strokeWidth={2}
                                />
                            ))}
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default LineUsageChart;
