/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import FeedbackIcon from '@mui/icons-material/ThumbUpAltOutlined';
import UsageStatsIcon from '@mui/icons-material/Timeline';
import Box from '@mui/material/Box';
import { useState } from 'react';
import {
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';
import address from '../resources/address';
import customer from '../resources/customer';
import license from '../resources/license';
import channel from '../resources/channel';
import subscription from '../resources/subscription';
import user from '../resources/user';
import SubMenu from './SubMenu';

type MenuName = 'menuCustomerData' | 'menuUsageStats';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCustomerData: true,
        menuUsageStats: false,
    });

    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {' '}
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuCustomerData')}
                isOpen={state.menuCustomerData}
                name="Customer Data"
                icon={<customer.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/customer"
                    state={{ _scrollToTop: true }}
                    primaryText="Customers"
                    leftIcon={<customer.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/user"
                    state={{ _scrollToTop: true }}
                    primaryText="Users"
                    leftIcon={<user.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/address"
                    state={{ _scrollToTop: true }}
                    primaryText="Addresses"
                    leftIcon={<address.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/subscription"
                    state={{ _scrollToTop: true }}
                    primaryText="Subscriptions"
                    leftIcon={<subscription.icon />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to="/license"
                state={{ _scrollToTop: true }}
                primaryText="Licenses"
                leftIcon={<license.icon />}
                dense={dense}
            />
            <MenuItemLink
                to="/channel"
                state={{ _scrollToTop: true }}
                primaryText="Channels"
                leftIcon={<channel.icon />}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuUsageStats')}
                isOpen={state.menuUsageStats}
                name="Usage"
                icon={<UsageStatsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/nostic_caries_stats"
                    state={{ _scrollToTop: true }}
                    primaryText="Service Stats"
                    leftIcon={<customer.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/nostic_caries_user_stats"
                    state={{ _scrollToTop: true }}
                    primaryText="User Stats"
                    leftIcon={<customer.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/customer_subscription_view"
                    state={{ _scrollToTop: true }}
                    primaryText="Subscr. Stats"
                    leftIcon={<subscription.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/prediction"
                    state={{ _scrollToTop: true }}
                    primaryText="Feedback"
                    leftIcon={<FeedbackIcon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
