/* eslint-disable @typescript-eslint/no-unused-vars */
import { Delete } from '@mui/icons-material';
import { Alert, AlertTitle, Typography } from '@mui/material';
import * as React from 'react';
import {
    Button,
    Confirm,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import { useMutation } from 'react-query';

interface ICustomerDeleteButtonProps {}

const CustomerDeleteButton: React.FunctionComponent<
    ICustomerDeleteButtonProps
> = (props) => {
    const [open, setOpen] = React.useState(false);
    const redirect = useRedirect();
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const { mutate, isLoading, error, isError, isSuccess } = useMutation(
        ['id', record?.id],
        () => dataProvider.deleteCustomer(record?.id),
        {
            onError: () => {
                notify('Cannot delete the customer. Please try again.', {
                    type: 'error',
                });
            },
            onSuccess: () => {
                setOpen(false);
                notify('Customer successfully deleted.', {
                    type: 'success',
                });
                redirect('/customer');
            },
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        mutate();
    };

    return (
        <>
            <Button
                label="Delete"
                size="medium"
                sx={{ color: 'error.main' }}
                onClick={handleClick}
            >
                <Delete />
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Delete Customer"
                content={
                    <>
                        <Typography>
                            Are you sure you want to delete the customer?
                        </Typography>
                        <Alert severity="error" sx={{ mt: 2 }}>
                            <AlertTitle>Caution</AlertTitle>
                            <Typography>
                                This will also delete related data such as
                                subscriptions and addresses etc.
                            </Typography>
                        </Alert>
                    </>
                }
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default React.memo(CustomerDeleteButton);
