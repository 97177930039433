import { gql } from '@apollo/client';

export const DELETE_CUSTOMER = gql`
    mutation delete_customer($id: bigint!) {
        update_customer_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
        ) {
            id
        }
        update_address(
            where: { customer_id: { _eq: $id } }
            _set: { deleted_at: "now()" }
        ) {
            affected_rows
        }
        update_user(
            where: { customer_id: { _eq: $id } }
            _set: { deleted_at: "now()" }
        ) {
            affected_rows
        }
        update_subscription(
            where: { customer_id: { _eq: $id } }
            _set: { deleted_at: "now()" }
        ) {
            affected_rows
        }
    }
`;
