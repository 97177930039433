import {
    ApolloClient,
    createHttpLink,
    GraphQLRequest,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthenticationResult } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import buildHasuraProvider from 'ra-data-hasura';
import { useEffect, useMemo, useState } from 'react';
import { DataProvider, Identifier, RaRecord } from 'react-admin';
import { scopeRequest } from '../authprovider/authConfig';
import { parseValidityPart, toDbDaterange } from '../util';
import customBuildFields from './custom-build-fields';
import { DELETE_CUSTOMER } from './custom-queries';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GAPI_URL,
});
interface Response {
    data: any[] | any;
    total: number;
}

const adaptQueryFilter = (params: Record<string, any>) => {
    const includePagination = !(
        params.pagination?.page === 0 && params.pagination?.perPage === 0
    );
    const newParams = {
        ...params,
        pagination: includePagination ? params.pagination : {},
    };
    return newParams;
};

const parseSubscription = (subscription: RaRecord) => ({
    ...subscription,
    valid_from: parseValidityPart('from', subscription.validity),
    valid_to: parseValidityPart('to', subscription.validity),
});

const transformSubscription = (subscription: Record<string, any>) => ({
    ...subscription,
    validity: toDbDaterange(subscription.valid_from, subscription.valid_to),
});

const parseResponse = (resource: string, response: Response): Response => {
    if (resource === 'subscription') {
        if (Array.isArray(response.data)) {
            return {
                ...response,
                data: response.data.map((subscription: RaRecord) =>
                    parseSubscription(subscription)
                ),
            };
        }
        return {
            ...response,
            data: parseSubscription(response.data),
        };
    }
    return response;
};

const transormRequest = (
    resource: string,
    params: Record<string, any>
): Promise<any> => {
    if (resource === 'subscription') {
        return Promise.resolve({
            ...params,
            data: transformSubscription(params.data),
        });
    }
    return Promise.resolve(params);
};

const updateRequestContext = (
    context?: Record<string, any>,
    authenticationResult?: AuthenticationResult
) => ({
    ...context,
    headers: {
        ...context?.headers,
        authorization: authenticationResult?.accessToken
            ? `Bearer ${authenticationResult?.accessToken}`
            : '',
    },
});

const useDataProvider = () => {
    const [dataProvider, setDataProvider] = useState<DataProvider | object>({});
    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const authLink = useMemo(
        () =>
            setContext((request: GraphQLRequest) =>
                instance
                    .acquireTokenSilent({
                        ...scopeRequest,
                        account: accounts[0],
                        forceRefresh: false,
                    })
                    .then((authenticationResult: AuthenticationResult) =>
                        updateRequestContext(
                            request.context,
                            authenticationResult
                        )
                    )
            ),
        [instance, scopeRequest, accounts]
    );

    useEffect(() => {
        const buildDataProvider = async (): Promise<void> => {
            if (!isAuthenticated) return;

            const client = new ApolloClient({
                link: authLink.concat(httpLink),
                cache: new InMemoryCache(),
            });

            const dp = await buildHasuraProvider(
                {
                    client,
                },
                { buildFields: customBuildFields }
            ).then((hdp: any) => ({
                getList: (resource: string, params: Record<string, any>) =>
                    hdp.getList(resource, adaptQueryFilter(params)),
                getOne: (resource: string, params: Record<string, any>) =>
                    hdp
                        .getOne(resource, adaptQueryFilter(params))
                        .then((response: Response) =>
                            parseResponse(resource, response)
                        ),
                getMany: (resource: string, params: Record<string, any>) =>
                    hdp.getMany(resource, adaptQueryFilter(params)),
                getManyReference: (
                    resource: string,
                    params: Record<string, any>
                ) => hdp.getManyReference(resource, adaptQueryFilter(params)),
                update: (resource: string, params: Record<string, any>) =>
                    transormRequest(resource, params).then(
                        (p: Record<string, any>) => hdp.update(resource, p)
                    ),
                updateMany: (resource: string, params: Record<string, any>) =>
                    hdp.updateMany(resource, params),
                create: (resource: string, params: Record<string, any>) =>
                    transormRequest(resource, params).then(
                        (p: Record<string, any>) => hdp.create(resource, p)
                    ),
                delete: (resource: string, params: Record<string, any>) =>
                    hdp.update(resource, {
                        ...params,
                        data: {
                            deleted_at: new Date().toISOString(),
                        },
                    }),
                deleteMany: (resource: string, params: Record<string, any>) =>
                    hdp.updateMany(resource, {
                        ...params,
                        data: {
                            deleted_at: new Date().toISOString(),
                        },
                    }),
                deleteCustomer: (id: Identifier) =>
                    client.mutate({
                        mutation: DELETE_CUSTOMER,
                        variables: {
                            id,
                        },
                    }),
            }));
            setDataProvider(() => dp);
        };
        buildDataProvider();
    }, [isAuthenticated]);
    return dataProvider;
};

export default useDataProvider;
