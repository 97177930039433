import Typography from '@mui/material/Typography';
import * as React from 'react';
import {
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    FunctionField,
    RaRecord,
    TextField,
    useRecordContext,
} from 'react-admin';
import EditDialogButton from '../../components/dialog-button/EditDialogButton';
import { formatDaterange } from '../../util';
import LinkField from '../shared/LinkField';
import SubscriptionFormFields from './SubscriptionFormFields';

interface ISubscriptionDatagridProps {
    relatedTo?: string;
}

const SubscriptionDatagrid: React.FunctionComponent<
    ISubscriptionDatagridProps
> = ({ relatedTo, ...rest }) => {
    return (
        <Datagrid
            size="medium"
            empty={<Typography>No subscriptions found</Typography>}
            bulkActionButtons={false}
            {...rest}
        >
            <TextField source="type" />
            <DateField source="created_at" showTime />
            <LinkField source="customer" value={['email']} />
            <FunctionField
                source="enabled"
                render={(r: RaRecord | undefined) => {
                    if (r && r.enabled) return 'Yes';
                    return 'No';
                }}
            />
            <FunctionField
                label="Remaining Days"
                source="remaining_days"
                render={(r: RaRecord | undefined) => {
                    if (r) {
                        if (r.is_valid) {
                            return r.remaining_days + 1;
                        }
                    }
                    return 0;
                }}
            />
            <FunctionField
                label="Validity"
                source="validity"
                render={(record: RaRecord | undefined) => {
                    if (record && record.validity) {
                        return formatDaterange(record.validity);
                    }
                    return '';
                }}
            />
            <FunctionField
                label="License"
                render={(r: RaRecord | undefined) =>
                    r?.license.payment_interval
                        ? `${r?.license.type} - ${r?.license.payment_interval}`
                        : r?.license.type
                }
            />
            <FunctionField
                label="Channel"
                render={(r: RaRecord | undefined) => {
                    if (!r?.channel) {
                        return r?.distributionChannel.channel;
                    }
                    return r.channel;
                }}
            />
            <EditDialogButton
                resource="subscription"
                formFileds={<SubscriptionFormFields />}
                relatedTo={relatedTo}
            />
            <DeleteWithConfirmButton redirect={false} />
        </Datagrid>
    );
};

export default SubscriptionDatagrid;
