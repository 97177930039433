import React from 'react';
import {
    Edit,
    EditProps,
    FormTab,
    RaRecord,
    SaveButton,
    TabbedForm,
    Toolbar,
    useRecordContext,
} from 'react-admin';
import Addresses from './Addresses';
import CustomerDeleteButton from './CustomerDeleteButton';
import CustomerFormFields from './CustomerFormFields';
import CustomerStats from './CustomerStats';
import Subscriptions from './Subscriptions';
import Users from './Users';

interface ICustomerEditProps extends EditProps {}

const CustomerTitle = () => {
    const record = useRecordContext();
    return (
        <span>
            <b>Customer:</b>{' '}
            {record
                ? `${record.company || 'no company'} - ${record.email}`
                : ''}
        </span>
    );
};

const CustomToolbar = (props: any) => (
    <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
        <SaveButton />
        <CustomerDeleteButton />
    </Toolbar>
);

const CustomerEdit: React.FunctionComponent<ICustomerEditProps> = (props) => {
    const transform = (data: RaRecord) => ({
        ...data,
        size: data.size === '' ? null : data.size,
    });

    return (
        <Edit {...props} transform={transform} title={<CustomerTitle />}>
            <TabbedForm toolbar={<CustomToolbar />}>
                <FormTab label="Customer Details">
                    <CustomerFormFields />
                </FormTab>
                <FormTab label="Addresses">
                    <Addresses />
                </FormTab>
                <FormTab label="Subscriptions">
                    <Subscriptions />
                </FormTab>
                <FormTab label="Usage Stats">
                    <CustomerStats />
                </FormTab>
                <FormTab label="Users">
                    <Users />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default React.memo(CustomerEdit);
