import LicenseIcon from '@mui/icons-material/VerifiedUserOutlined';
import LicenseList from './LicenseList';

export default {
    list: LicenseList,
    icon: LicenseIcon,
    recordRepresentation: (record: any) =>
        `${record?.country ? record?.country + ' - ' : ''} ${record.type} - ${
            record.product
        }`,
};
