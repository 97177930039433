import React from 'react';
import { Popover } from '@mui/material';
import {
    addDays,
    differenceInCalendarDays,
    format,
    formatISO,
    subDays,
} from 'date-fns';
import { Button } from 'ra-ui-materialui';
import { useEffect, useState } from 'react';
import {
    Loading,
    RaRecord,
    useDataProvider,
    useRecordContext,
} from 'react-admin';
import { DateRangePicker } from 'react-date-range';
import { DateFilter } from '../../types';
import { DATE_FORMAT } from '../../util';
import staticDateRanges from '../dashboard/staticDateRanges';
import AreaUsageChart from './AreaUsageChart';
import { getTotalsPerDay, TotalsPerDay } from './predictionsHelper';

export const createFilter = (
    from: Date,
    to: Date,
    record: RaRecord,
    aggregateOnCustomerLevel: boolean
): Record<string, any> => ({
    'date@_gte': formatISO(from, {
        representation: 'date',
    }),
    'date@_lte': formatISO(addDays(to, 1), {
        representation: 'date',
    }),
    user_id: !aggregateOnCustomerLevel ? record?.id : undefined,
    email: aggregateOnCustomerLevel ? record?.email : '',
});

export interface IUserUsageChart {
    aggregateOnCustomerLevel?: boolean;
}

const UsageChart: React.FunctionComponent<IUserUsageChart> = ({
    aggregateOnCustomerLevel = false,
}) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [totalsPerDay, setTotalsPerDay] = useState<TotalsPerDay[]>([]);
    const [dateFilter, setDateFilter] = useState<DateFilter[]>([
        {
            startDate: subDays(new Date(), 13),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        const fetchUserStats = async () => {
            const startDate = dateFilter[0].startDate;
            const endDate = dateFilter[0].endDate;

            const numberOfDays = differenceInCalendarDays(endDate, startDate);

            const res = await dataProvider.getList('nostic_caries_user_stats', {
                filter: createFilter(
                    startDate,
                    endDate,
                    record,
                    aggregateOnCustomerLevel
                ),
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 0, perPage: 0 },
            });
            setTotalsPerDay(getTotalsPerDay(numberOfDays, endDate, res.data));
        };
        if (record) fetchUserStats();
    }, [dataProvider, record, dateFilter]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!totalsPerDay) return <Loading />;

    const filter = (
        <>
            <Button
                style={{ paddingLeft: '0' }}
                size="large"
                variant="text"
                label={`${format(
                    dateFilter[0].startDate,
                    DATE_FORMAT
                )} - ${format(dateFilter[0].endDate, DATE_FORMAT)}`}
                aria-describedby={id}
                onClick={handleClick}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateRangePicker
                    onChange={(item) => setDateFilter([item.selection] as any)}
                    staticRanges={staticDateRanges}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateFilter}
                    direction="vertical"
                />
            </Popover>
        </>
    );

    return (
        <AreaUsageChart
            title={`Usage Stats for ${
                aggregateOnCustomerLevel ? 'Customer' : 'User'
            }`}
            titleAddition={filter}
            data={totalsPerDay}
        />
    );
};

export default React.memo(UsageChart);
