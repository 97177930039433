import { Box } from '@mui/material';
import * as React from 'react';
import { required, SimpleFormProps, TextInput } from 'react-admin';
import { AddressTypeInput, CountryInput } from '../shared/SelectInput';

interface IAddressFormFieldsProps extends Omit<SimpleFormProps, 'children'> {}

const AddressFormFields: React.FunctionComponent<
    IAddressFormFieldsProps
> = () => (
    <>
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <AddressTypeInput validate={required()} fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <CountryInput validate={required()} fullWidth />
            </Box>
        </Box>
        <TextInput source="line1" validate={required()} fullWidth />
        <TextInput source="line2" fullWidth />
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                    source="postal_code"
                    validate={required()}
                    fullWidth
                />
            </Box>
            <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="city" validate={required()} fullWidth />
            </Box>
        </Box>
    </>
);

export default AddressFormFields;
