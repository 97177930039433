import { useMsal } from '@azure/msal-react';
import { Box, Button, Link, Typography } from '@mui/material';
import { useLogin } from 'ra-core';
import * as React from 'react';
import background from '../assets/background.jpeg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { loginRequest } from './authConfig';

const Copyright = (props: any) => (
    <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
    >
        {'Copyright © '}
        <Link color="inherit" href="https://nostic.ch/">
            Nostic Solutions AG
        </Link>{' '}
        {new Date().getFullYear()}.
    </Typography>
);

interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = () => {
    const { instance } = useMsal();
    const login = useLogin();

    const handleLogin = () =>
        instance
            .loginPopup(loginRequest)
            // eslint-disable-next-line no-console
            .catch((err: any) => console.error(err));

    return (
        <div
            style={{
                background: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
        >
            <Box
                bgcolor="white"
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '24px',
                    borderRadius: '12px',
                    boxShadow: '10px 12px 52px 10px rgba(0,0,0,0.75)',
                }}
            >
                <Logo />
                <Box sx={{ mt: 1, padding: '24px' }}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => login({ loginHandler: handleLogin })}
                    >
                        Sign In with Office365
                    </Button>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Box>
        </div>
    );
};

export default LoginPage;
