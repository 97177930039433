import {
    AuthenticationResult,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Admin, DataProvider, Loading, Resource } from 'react-admin';
import './App.css';
import { loginRequest } from './authprovider/authConfig';
import LoginPage from './authprovider/LoginPage';
import useDataProvider from './dataprovider/useDataProvider';
import { Layout } from './layout';
import address from './resources/address';
import customer from './resources/customer';
import Dashboard from './resources/dashboard';
import feedback from './resources/feedback';
import license from './resources/license';
import channel from './resources/channel';
import servicestats from './resources/servicestats';
import subscription from './resources/subscription';
import user from './resources/user';
import userstats from './resources/userstats';
import subscriptionStats from './resources/subscription-stats';
import { lightTheme } from './layout/themes';

interface LoginParams {
    loginHandler: () => Promise<AuthenticationResult>;
}

interface LogoutParams {
    logoutHandler: () => Promise<void>;
}

const App = (): React.ReactElement => {
    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const dataProvider = useDataProvider();

    const authenticate = useCallback(
        () =>
            instance
                .ssoSilent(loginRequest)
                .then(() => Promise.resolve())
                .catch((err) => {
                    if (err instanceof InteractionRequiredAuthError) {
                        instance
                            .loginPopup(loginRequest)
                            .then(() => Promise.resolve())
                            .catch(() => Promise.reject());
                    }
                }),
        [instance, loginRequest]
    );

    useEffect(() => {
        authenticate();
    }, [isAuthenticated]);

    const authProvider = {
        login: (params: LoginParams) => {
            const { loginHandler } = params;
            return loginHandler();
        },
        checkAuth: () => {
            if (isAuthenticated) {
                return Promise.resolve();
            }
            return authenticate();
        },
        checkError: () => Promise.resolve(),
        logout: (params: LogoutParams) => {
            const { logoutHandler } = params;
            return logoutHandler();
        },
        getIdentity: () => {
            if (accounts[0]) {
                return Promise.resolve({
                    id: accounts[0] && accounts[0].username,
                    fullName: accounts[0] && accounts[0].name,
                });
            }
            return Promise.reject();
        },

        getPermissions: () => Promise.resolve(),
    };

    if (isEmpty(dataProvider))
        return (
            <Loading
                loadingPrimary="Loading Nostic Admin"
                loadingSecondary=""
            />
        );

    return (
        <Admin
            title="Nostic Admin"
            dashboard={Dashboard}
            dataProvider={dataProvider as DataProvider}
            authProvider={authProvider}
            layout={Layout}
            loginPage={LoginPage}
            // logoutButton={LogoutButton}
            disableTelemetry
            theme={lightTheme}
        >
            <Resource name="customer" {...customer} />
            <Resource name="user" {...user} />
            <Resource name="address" {...address} />
            <Resource name="subscription" {...subscription} />
            <Resource name="license" {...license} />
            <Resource name="channel" {...channel} />
            <Resource name="nostic_caries_stats" {...servicestats} />
            <Resource name="nostic_caries_user_stats" {...userstats} />
            <Resource
                name="customer_subscription_view"
                {...subscriptionStats}
            />
            <Resource name="prediction" {...feedback} />
        </Admin>
    );
};

export default App;
