import gql from 'graphql-tag';

export const GET_LIST_SUBSCRIPTION = gql`
    {
        id
        alt_id
        created_at
        updated_at
        cancelled_at
        type
        automatic_renewal
        is_valid
        is_latest
        validity
        enabled
        remaining_days
        customer_id
        customer {
            id
            email
            company
        }
        distribution_channel_id
        distributionChannel {
            id
            channel
        }
        channel
        license_id
        license {
            id
            type
            payment_interval
        }
    }
`;

export const GET_ONE_SUBSCRIPTION = gql`
    {
        id
        alt_id
        created_at
        updated_at
        cancelled_at
        type
        number_of_users
        automatic_renewal
        is_valid
        is_latest
        validity
        enabled
        remaining_days
        distribution_channel_id
        license_id
        customer_id
    }
`;
