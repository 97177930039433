import React from 'react';
import { useRecordContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import CustomerUsageChart from '../shared/UsageChart';

const CustomerStats: React.FC = () => {
    const record = useRecordContext();
    const location = useLocation();

    const shouldRender = location.pathname.endsWith('/3');
    if (!shouldRender || !record) return null;

    return (
        <div style={{ width: '100%' }}>
            <CustomerUsageChart aggregateOnCustomerLevel />
        </div>
    );
};

export default CustomerStats;
