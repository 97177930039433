import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { ReferenceManyField, useRecordContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import CreateDialogButton from '../../components/dialog-button/CreateDialogButton';
import AddressDatagrid from '../address/AddressDatagrid';
import AddressFormFields from '../address/AddressFormFields';

interface IAddressesProps {}

const Addresses: React.FunctionComponent<IAddressesProps> = () => {
    const record = useRecordContext();
    const location = useLocation();

    const shouldRender = location.pathname.endsWith('/1');
    if (!shouldRender || !record) return null;

    const relatedTo = record?.company || record?.email;

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Addresses
                </Typography>
                <CreateDialogButton
                    resource="address"
                    formFileds={<AddressFormFields />}
                />
            </Box>
            <ReferenceManyField
                reference="address"
                target="customer_id"
                label="Addresses"
            >
                <AddressDatagrid relatedTo={relatedTo} />
            </ReferenceManyField>
        </>
    );
};

export default React.memo(Addresses);
