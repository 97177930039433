import OverdueIcon from '@mui/icons-material/AccessAlarmOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { doesStartInFuture } from '../../util';
import CardWithIcon from './CardWithIcon';
import { SubscriptionState } from './types';

interface IExpiringSubscriptionsProps {
    title: string;
    data: SubscriptionState;
    onAllClick: string;
}

const Subscriptions: React.FunctionComponent<IExpiringSubscriptionsProps> = (
    props
) => {
    const { title, data, onAllClick } = props;

    return (
        <CardWithIcon
            to="/subscription"
            icon={CommentIcon}
            title={title}
            subtitle={`${data.total || 0}`}
        >
            <List>
                {data.subscriptions.map((s) => {
                    const icon =
                        s.remaining_days > 0 && s.remaining_days < 15 ? (
                            <OverdueIcon />
                        ) : (
                            <CommentIcon />
                        );
                    const subtitle = () => {
                        if (doesStartInFuture(s.validity)) {
                            return 'not yet started';
                        }
                        return `${s.remaining_days + 1} days remaining`;
                    };

                    return (
                        <ListItem
                            key={s.id}
                            button
                            component={Link}
                            to={`/customer/${s.customer_id}/2`}
                            alignItems="flex-start"
                        >
                            <ListItemIcon
                                style={{
                                    color:
                                        s.remaining_days > 0 &&
                                        s.remaining_days < 15
                                            ? '#E7625F'
                                            : '#F6A21E',
                                }}
                            >
                                {icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={`${
                                    s.customer.company ||
                                    s.customer.email ||
                                    'No customer data'
                                }`}
                                secondary={subtitle()}
                                sx={{
                                    overflowY: 'hidden',
                                    height: '4em',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    paddingRight: 0,
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
            <Box flexGrow="1">&nbsp;</Box>
            <Button
                sx={{
                    borderRadius: 0,
                }}
                component={Link}
                to={onAllClick}
                size="small"
                color="primary"
            >
                <Box
                    p={1}
                    sx={{
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    Show more
                </Box>
            </Button>
        </CardWithIcon>
    );
};

export default Subscriptions;
