import CustomerIcon from '@mui/icons-material/GroupOutlined';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';
import CustomerList from './CustomerList';

export * from './queries';

export default {
    list: CustomerList,
    create: CustomerCreate,
    edit: CustomerEdit,
    icon: CustomerIcon,
};
