import * as React from 'react';
import { Create, CreateProps, RaRecord, SimpleForm } from 'react-admin';
import CustomerFormFields from './CustomerFormFields';

interface ICustomerCreateProps extends CreateProps {}

const CustomerCreate: React.FunctionComponent<ICustomerCreateProps> = () => {
    const transform = (data: RaRecord) => ({
        ...data,
        size: data.size === '' ? null : data.size,
    });
    return (
        <Create redirect="edit" transform={transform}>
            <SimpleForm>
                <CustomerFormFields />
            </SimpleForm>
        </Create>
    );
};

export default React.memo(CustomerCreate);
