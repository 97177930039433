import gql from 'graphql-tag';

export const GET_LIST_CUSTOMER = gql`
    {
        id
        alt_id
        type
        first_name
        last_name
        email
        company
        size
        created_at
        deleted_at
    }
`;

export const GET_ONE_CUSTOMER = gql`
    {
        id
        alt_id
        type
        first_name
        last_name
        email
        company
        size
        size_checked_at
        created_at
        updated_at
        number_of_dentists
        number_of_dental_hygienists
        number_of_assistants
    }
`;
