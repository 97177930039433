import ShowChart from '@mui/icons-material/ShowChart';
import { Typography } from '@mui/material';
import * as React from 'react';
import { Datagrid, DateField, ShowButton, TextField } from 'react-admin';
import EditDialogButton from '../../components/dialog-button/EditDialogButton';
import LinkField from '../shared/LinkField';
import UserFormFields from './UserFormFields';

interface IUserDatagridProps {
    relatedTo?: string;
}

const UserDatagrid: React.FunctionComponent<IUserDatagridProps> = ({
    relatedTo,
}) => (
    <Datagrid
        size="medium"
        empty={<Typography>No Users found</Typography>}
        bulkActionButtons={false}
    >
        <DateField source="created_at" locales="en-GB" showTime />
        <TextField source="login" />
        <TextField source="type" />
        <LinkField source="customer" value={['email']} />
        <EditDialogButton
            resource="user"
            formFileds={<UserFormFields />}
            relatedTo={relatedTo}
            show={(r) => r.type !== 'APP_CLIENT'}
        />
        <ShowButton icon={<ShowChart />} />
    </Datagrid>
);

export default UserDatagrid;
