import * as React from 'react';
import { List, SelectInput, TextInput } from 'react-admin';
import { addressTypes, countries } from '../shared/SelectInput';
import AddressDatagrid from './AddressDatagrid';

const filters = [
    <TextInput
        key="search"
        source="postal_code@_eq,city@_ilike,customer#email@_ilike"
        label="Search"
        alwaysOn
        resettable
    />,
    <SelectInput key="type" source="type" choices={addressTypes} />,
    <SelectInput key="country" source="country" choices={countries} />,
];

const AddressList = (props: any): React.ReactElement => (
    <List
        {...props}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={filters}
        hasCreate={false}
        debounce={1000}
    >
        <AddressDatagrid />
    </List>
);

export default AddressList;
