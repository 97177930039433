import gql from 'graphql-tag';

export const GET_LIST_PREDICTION = gql`
    {
        id
        alt_id
        created_at
        user_id
        user {
            login
            customer_id
            customer {
                company
                email
            }
        }
        result
        rating
        comment
    }
`;
