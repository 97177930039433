/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    RaRecord,
    SelectInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import CreateDialogButton from '../../components/dialog-button/CreateDialogButton';
import EditDialogButton from '../../components/dialog-button/EditDialogButton';
import {
    countries,
    licenseTypes,
    paymentIntervals,
} from '../shared/SelectInput';
import LicenseFormFields from './LicenseFormFields';

const filters = [
    <SelectInput key="type" source="type" choices={licenseTypes} />,
    <SelectInput
        key="payment interval"
        source="payment_interval"
        choices={paymentIntervals}
    />,
    <SelectInput key="country" source="country" choices={countries} />,
];

const LicenseListActions = () => (
    <TopToolbar>
        <CreateDialogButton
            resource="license"
            formFileds={<LicenseFormFields />}
            variant="text"
            buttonText="Create"
            size="small"
            defaultValues={{
                product: 'nostic.caries',
            }}
        />
        <ExportButton />
        <FilterButton />
    </TopToolbar>
);

const LicenseList = (props: any): React.ReactElement => (
    <List
        {...props}
        actions={<LicenseListActions />}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={filters}
    >
        <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
            <DateField source="created_at" locales="de-DE" showTime />
            <TextField source="type" />
            <TextField source="payment_interval" />
            <TextField source="product" />
            <FunctionField
                label="Range"
                render={(record: RaRecord | undefined) =>
                    `${record?.number_of_users_from || ''} - ${
                        record?.number_of_users_to || ''
                    }`
                }
            />
            <TextField source="country" />
            <TextField source="price" />
            <EditDialogButton
                resource="license"
                formFileds={<LicenseFormFields />}
            />
            <DeleteWithConfirmButton redirect={false} />
        </Datagrid>
    </List>
);

export default LicenseList;
