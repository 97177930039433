import { Box, Typography } from '@mui/material';
import * as React from 'react';
import {
    DateInput,
    email,
    NumberInput,
    required,
    SimpleFormProps,
    TextInput,
} from 'react-admin';
import { CustomerTypeInput, SizeInput } from '../shared/SelectInput';

interface ICustomerFormFieldsProps extends Omit<SimpleFormProps, 'children'> {}

const Separator = () => <Box pt="1em" />;

const CustomerFormFields: React.FunctionComponent<
    ICustomerFormFieldsProps
> = () => (
    <Box sx={{ width: { xs: '100%', md: 600 } }}>
        <Typography variant="h6" gutterBottom>
            General
        </Typography>
        <CustomerTypeInput validate={required()} />
        <TextInput source="company" validate={required()} fullWidth />
        <TextInput
            source="email"
            type="email"
            validate={[required(), email()]}
            fullWidth
        />
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="first_name" fullWidth defaultValue={null} />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="last_name" fullWidth defaultValue={null} />
            </Box>
        </Box>
        <Separator />
        <Typography variant="h6" gutterBottom>
            Practice Size
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <SizeInput />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <DateInput source="size_checked_at" fullWidth />
            </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                    source="number_of_dentists"
                    label="Dentits"
                    fullWidth
                    defaultValue={null}
                />
            </Box>
            <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
                mr={{ xs: 0, sm: '0.5em' }}
            >
                <NumberInput
                    source="number_of_dental_hygienists"
                    label="DHs"
                    fullWidth
                    defaultValue={null}
                />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                    source="number_of_assistants"
                    label="Assistants"
                    fullWidth
                    defaultValue={null}
                />
            </Box>
        </Box>
    </Box>
);

export default React.memo(CustomerFormFields);
