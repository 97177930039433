import gql from 'graphql-tag';

export const GET_LIST_USER = gql`
    {
        id
        alt_id
        created_at
        sub
        login
        type
        customer_id
        customer {
            id
            email
            company
        }
    }
`;

export const GET_ONE_USER = gql`
    {
        id
        alt_id
        created_at
        sub
        login
        type
        customer_id
        customer {
            id
            email
            company
        }
    }
`;
