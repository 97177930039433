import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { ReferenceManyField } from 'react-admin';
import { useLocation } from 'react-router-dom';
import CreateDialogButton from '../../components/dialog-button/CreateDialogButton';
import SubscriptionDatagrid from '../subscription/SubscriptionDatagrid';
import SubscriptionFormFields from '../subscription/SubscriptionFormFields';

interface ISubscriptionsProps {}

const Subscriptions: React.FunctionComponent<ISubscriptionsProps> = () => {
    const location = useLocation();

    const shouldRender = location.pathname.endsWith('/2');
    if (!shouldRender) return null;

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Subscriptions
                </Typography>
                <CreateDialogButton
                    resource="subscription"
                    formFileds={<SubscriptionFormFields />}
                    defaultValues={{ enabled: true }}
                />
            </Box>
            <ReferenceManyField
                reference="subscription"
                target="customer_id"
                label="Subscriptions"
            >
                <SubscriptionDatagrid />
            </ReferenceManyField>
        </>
    );
};

export default React.memo(Subscriptions);
