import PersonIcon from '@mui/icons-material/AccountBoxOutlined';
import UserList from './UserList';
import UserShow from './UserShow';

export * from './queries';

export default {
    list: UserList,
    show: UserShow,
    icon: PersonIcon,
};
