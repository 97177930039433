import CloseIcon from '@mui/icons-material/Close';
import {
    DialogTitle as MuiDialogTitle,
    DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

interface IDialogTitleProps extends MuiDialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const DialogTitle: React.FunctionComponent<IDialogTitleProps> = ({
    id,
    children,
    onClose,
    ...rest
}) => (
    <MuiDialogTitle
        sx={{
            m: 0,
            p: 2,
            backgroundColor: 'primary.main',
            color: 'white',
        }}
        {...rest}
    >
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        ) : null}
    </MuiDialogTitle>
);

export default DialogTitle;
