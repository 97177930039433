import Typography from '@mui/material/Typography';
import * as React from 'react';
import {
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    TextField,
} from 'react-admin';
import EditDialogButton from '../../components/dialog-button/EditDialogButton';
import LinkField from '../shared/LinkField';
import AddressFormFields from './AddressFormFields';

interface IAddressDatagridProps {
    relatedTo?: string;
}

const AddressDatagrid: React.FunctionComponent<IAddressDatagridProps> = ({
    relatedTo,
}) => (
    <Datagrid
        size="medium"
        empty={<Typography>No Addresses found</Typography>}
        bulkActionButtons={false}
    >
        <TextField source="type" />
        <DateField source="created_at" locales="en-GB" showTime />
        <TextField source="line1" />
        <TextField source="line2" />
        <LinkField source="customer" value={['email']} />
        <TextField source="postal_code" />
        <TextField source="city" />
        <TextField source="country" />
        <EditDialogButton
            resource="address"
            formFileds={<AddressFormFields />}
            relatedTo={relatedTo}
        />
        <DeleteWithConfirmButton redirect={false} />
    </Datagrid>
);

export default AddressDatagrid;
