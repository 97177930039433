/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import * as React from 'react';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { TotalsPerDay } from './predictionsHelper';

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

interface IUsageChartProps {
    title: string;
    data: TotalsPerDay[];
    titleAddition?: React.ReactElement;
}

const UsageChart: React.FunctionComponent<IUsageChartProps> = (props) => {
    const { title, data, titleAddition } = props;

    const totalPredictions = data.reduce((prev, curr) => {
        return prev + curr.totalPredictions;
    }, 0);

    return (
        <Card>
            <CardHeader
                title={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {title} {titleAddition}
                        </span>
                        <span>Total Predictions: {totalPredictions}</span>
                    </Box>
                }
            />
            <CardContent>
                <ResponsiveContainer width="100%" minHeight={350}>
                    <AreaChart data={data} style={{ position: 'absolute' }}>
                        <defs>
                            <linearGradient
                                id="colorUv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="5%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="#8884d8"
                                    stopOpacity={0.3}
                                />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="date"
                            tickFormatter={dateFormatter}
                            reversed
                        />
                        <YAxis dataKey="totalPredictions" name="Predictions" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            formatter={(value: any) =>
                                new Intl.NumberFormat(undefined, {
                                    style: 'decimal',
                                }).format(value as any)
                            }
                            labelFormatter={(label: any) =>
                                dateFormatter(label)
                            }
                        />
                        <Area
                            type="monotone"
                            dataKey="totalPredictions"
                            stroke="#8884d8"
                            strokeWidth={2.5}
                            fill="url(#colorUv)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default UsageChart;
