import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    Pagination,
    PaginationProps,
    TextField,
    TextInput,
} from 'react-admin';

const filters = [
    <TextInput
        key="search"
        source="user#login@_ilike,customer#company@_ilike"
        label="Search"
        alwaysOn
        resettable
    />,
];

const PostPagination = (props: JSX.IntrinsicAttributes & PaginationProps) => (
    <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

const UserStatsList = (props: any): React.ReactElement => (
    <List
        {...props}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={filters}
        filterDefaultValues={{ 'rating@_is_null': false }}
        pagination={<PostPagination />}
    >
        <Datagrid bulkActionButtons={false} size="medium">
            <DateField source="created_at" locales="en-GB" showTime />
            <TextField source="user.login" label="Login" />
            <TextField source="user.customer.company" label="Company" />
            <TextField source="result" label="S3 Link" />
            <TextField source="rating" />
            <TextField source="comment" />
        </Datagrid>
    </List>
);

export default UserStatsList;
