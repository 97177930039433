import { Box } from '@mui/material';
import React from 'react';
import {
    number,
    NumberInput,
    required,
    SimpleFormProps,
    TextInput,
} from 'react-admin';
import {
    CountryInput,
    CurrencyInput,
    LicenseTypeInput,
    PaymentIntervalInput,
} from '../shared/SelectInput';

interface ILicenseFormFieldsProps extends Omit<SimpleFormProps, 'children'> {}

const LicenseFormFields: React.FunctionComponent<
    ILicenseFormFieldsProps
> = () => (
    <>
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <LicenseTypeInput
                    source="type"
                    validate={required()}
                    fullWidth
                />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <PaymentIntervalInput source="payment_interval" fullWidth />
            </Box>
        </Box>
        <TextInput source="product" validate={required()} fullWidth />
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                    source="number_of_users_from"
                    validate={number()}
                    fullWidth
                />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                    source="number_of_users_to"
                    validate={number()}
                    fullWidth
                />
            </Box>
        </Box>
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <CurrencyInput source="currency" fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <CountryInput source="country" fullWidth />
            </Box>
        </Box>
        <TextInput source="price" validate={number()} fullWidth />
    </>
);

export default React.memo(LicenseFormFields);
