import { subDays, subMonths } from 'date-fns';
import { StaticRange } from 'react-date-range';

const staticDateRanges: StaticRange[] = [
    {
        label: 'Last 3 Days',
        range: () => ({
            startDate: subDays(new Date(), 2),
            endDate: new Date(),
        }),
        isSelected() {
            return false;
        },
    },
    {
        label: 'Last 10 Days',
        range: () => ({
            startDate: subDays(new Date(), 9),
            endDate: new Date(),
        }),
        isSelected() {
            return false;
        },
    },
    {
        label: 'Last 2 Weeks',
        range: () => ({
            startDate: subDays(new Date(), 13),
            endDate: new Date(),
        }),
        isSelected() {
            return false;
        },
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: subMonths(new Date(), 1),
            endDate: new Date(),
        }),
        isSelected() {
            return false;
        },
    },
    {
        label: 'Last 2 Month',
        range: () => ({
            startDate: subMonths(new Date(), 2),
            endDate: new Date(),
        }),
        isSelected() {
            return false;
        },
    },
];

export default staticDateRanges;
