import { Box } from '@mui/material';
import React from 'react';
import {
    BooleanInput,
    DateInput,
    RaRecord,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormProps,
} from 'react-admin';
import { SubscriptionTypeInput } from '../shared/SelectInput';

interface ISubscriptionFormFieldsProps
    extends Omit<SimpleFormProps, 'children'> {}

const SubscriptionFormFields: React.FunctionComponent<
    ISubscriptionFormFieldsProps
> = () => (
    <>
        <SubscriptionTypeInput validate={required()} />
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="license_id" reference="license">
                    <SelectInput
                        optionText={(r: RaRecord) =>
                            r.payment_interval
                                ? `${r.type} - ${r.payment_interval}`
                                : r.type
                        }
                        validate={required()}
                        fullWidth
                    />
                </ReferenceInput>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="channel" reference="channel">
                    <SelectInput
                        // optionText="channel"
                        validate={required()}
                        fullWidth
                    />
                </ReferenceInput>
            </Box>
        </Box>
        <Box
            display={{
                xs: 'block',
                sm: 'flex',
                width: '100%',
            }}
        >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <DateInput
                    source="valid_from"
                    validate={required()}
                    fullWidth
                />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <DateInput source="valid_to" validate={required()} fullWidth />
            </Box>
        </Box>
        <BooleanInput source="enabled" />
    </>
);

export default React.memo(SubscriptionFormFields);
