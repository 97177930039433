import * as React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    List,
    Pagination,
    PaginationProps,
    ResourceComponentInjectedProps,
    TextField,
    TextInput,
} from 'react-admin';

const filters = [
    <TextInput
        key="search"
        source="login@_ilike,company@_ilike,email@_ilike"
        label="Search"
        alwaysOn
        resettable
    />,
    <DateInput key="date" source="date" label="Usage at date" alwaysOn />,
];

const PostPagination = (props: JSX.IntrinsicAttributes & PaginationProps) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const UserStatsList = (
    props: ResourceComponentInjectedProps
): React.ReactElement => (
    <List
        {...props}
        sort={{ field: 'date', order: 'DESC' }}
        filters={filters}
        pagination={<PostPagination />}
    >
        <Datagrid bulkActionButtons={false} size="medium">
            <DateField source="date" locales="en-GB" />
            <TextField source="login" />
            <TextField source="company" />
            <TextField source="email" />
            <TextField source="predictions" label="#Predictions" />
        </Datagrid>
    </List>
);

export default UserStatsList;
