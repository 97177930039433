import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    Pagination,
    PaginationProps,
    TextField,
} from 'react-admin';

const PostPagination = (props: JSX.IntrinsicAttributes & PaginationProps) => (
    <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

const ServiceStatsList = (props: any): React.ReactElement => (
    <List
        {...props}
        sort={{ field: 'date', order: 'DESC' }}
        bulkActionButtons={false}
        pagination={<PostPagination />}
    >
        <Datagrid bulkActionButtons={false} size="medium">
            <DateField source="date" locales="en-GB" />
            <TextField source="predictions" />
            <TextField source="active_users" />
            <TextField source="predictions_per_user" />
        </Datagrid>
    </List>
);

export default ServiceStatsList;
