import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    Pagination,
    PaginationProps,
    ResourceComponentInjectedProps,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import { licenseTypes } from '../shared/SelectInput';

const filters = [
    <TextInput
        key="search"
        source="company@_ilike,first_name@_ilike,last_name@_ilike,email@_ilike"
        label="Search"
        alwaysOn
        resettable
    />,
    <SelectInput
        key="type"
        label="License Type"
        source="type@_ilike"
        choices={licenseTypes}
    />,
];

const PostPagination = (props: JSX.IntrinsicAttributes & PaginationProps) => (
    <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

const SubscriptionStatsList = (
    props: ResourceComponentInjectedProps
): React.ReactElement => (
    <List
        {...props}
        sort={{ field: 'end_date', order: 'ASC' }}
        filters={filters}
        pagination={<PostPagination />}
        title="Subscription overview"
    >
        <Datagrid bulkActionButtons={false} size="medium">
            <TextField source="company" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email" />
            <TextField source="type" />
            <DateField source="start_date" locales="en-GB" />
            <DateField source="end_date" locales="en-GB" />
            <NumberField source="total_predictions" label="Pred. since start" />
        </Datagrid>
    </List>
);

export default SubscriptionStatsList;
